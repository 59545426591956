<template>
  <div>
    <div class="list" v-if="localVal == '20221003'">
      <el-collapse accordion>
        <el-collapse-item v-for="itm in listDatas" :key="itm.name">
          <template slot="title">
            <img class="itm-icon" :src="require('@/assets/svg/done.svg')" />
            {{ itm.name }}
          </template>
          <div class="edit">
            <el-image
              v-for="_itm in itm.photos"
              :key="_itm"
              :src="_itm"
              :preview-src-list="itm.photos"
            />
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="none" v-else>
      <el-input
        placeholder="请输入暗号"
        v-model="inputPasswd"
        class="input-with-select"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="setLocal"
        ></el-button>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listDatas: [
        {
          name: "10.02 逛了南京夫子庙",
          photos: [
            require("@/assets/photos/02/481665147372_.pic_hd.jpg"),
            require("@/assets/photos/02/491665147537_.pic_hd.jpg"),
            require("@/assets/photos/02/501665147538_.pic_hd.jpg"),
            require("@/assets/photos/02/511665147539_.pic.jpg"),
            require("@/assets/photos/02/531665147541_.pic_hd.jpg"),
            require("@/assets/photos/02/541665147542_.pic_hd.jpg"),
            require("@/assets/photos/02/551665147543_.pic_hd.jpg"),
            require("@/assets/photos/02/561665147544_.pic_hd.jpg"),
            require("@/assets/photos/02/571665147544_.pic_hd.jpg"),
            require("@/assets/photos/02/581665147545_.pic_hd.jpg"),
            require("@/assets/photos/02/591665147546_.pic_hd.jpg"),
            require("@/assets/photos/02/601665147546_.pic_hd.jpg"),
            require("@/assets/photos/02/611665147547_.pic_hd.jpg"),
            require("@/assets/photos/02/621665147548_.pic_hd.jpg"),
            require("@/assets/photos/02/631665147549_.pic_hd.jpg"),
          ],
        },
        {
          name: "10.03 看海AND喝酒",
          photos: [
            require("/src/assets/photos/03/641665148676_.pic_hd.jpg"),
            require("/src/assets/photos/03/651665148677_.pic_hd.jpg"),
            require("/src/assets/photos/03/661665148678_.pic_hd.jpg"),
            require("/src/assets/photos/03/671665148679_.pic_hd.jpg"),
            require("/src/assets/photos/03/681665148679_.pic_hd.jpg"),
            require("/src/assets/photos/03/691665148679_.pic_hd.jpg"),
            require("/src/assets/photos/03/701665148680_.pic_hd.jpg"),
            require("/src/assets/photos/03/711665148681_.pic_hd.jpg"),
            require("/src/assets/photos/03/721665148683_.pic_hd.jpg"),
            require("/src/assets/photos/03/731665148684_.pic_hd.jpg"),
            require("/src/assets/photos/03/741665148686_.pic_hd.jpg"),
            require("/src/assets/photos/03/751665148687_.pic_hd.jpg"),
            require("/src/assets/photos/03/761665148688_.pic_hd.jpg"),
            require("/src/assets/photos/03/771665148688_.pic.jpg"),
            require("/src/assets/photos/03/781665148688_.pic.jpg"),
            require("/src/assets/photos/03/791665148688_.pic.jpg"),
            require("/src/assets/photos/03/801665148689_.pic_hd.jpg"),
            require("/src/assets/photos/03/811665148690_.pic_hd.jpg"),
            require("/src/assets/photos/03/821665148690_.pic.jpg"),
            require("/src/assets/photos/03/831665148691_.pic_hd.jpg"),
            require("/src/assets/photos/03/841665148691_.pic_hd.jpg"),
            require("/src/assets/photos/03/851665148692_.pic_hd.jpg"),
            require("/src/assets/photos/03/861665148692_.pic_hd.jpg"),
            require("/src/assets/photos/03/871665148693_.pic_hd.jpg"),
            require("/src/assets/photos/03/881665148693_.pic.jpg"),
          ],
        },
        {
          name: "10.04 干饭AND洗澡",
          photos: [
            require("/src/assets/photos/04/891665149397_.pic_hd.jpg"),
            require("/src/assets/photos/04/901665149398_.pic_hd.jpg"),
            require("/src/assets/photos/04/911665149399_.pic_hd.jpg"),
            require("/src/assets/photos/04/921665149400_.pic_hd.jpg"),
            require("/src/assets/photos/04/931665149401_.pic_hd.jpg"),
            require("/src/assets/photos/04/941665149402_.pic.jpg"),
            require("/src/assets/photos/04/951665149403_.pic_hd.jpg"),
            require("/src/assets/photos/04/961665149403_.pic.jpg"),
            require("/src/assets/photos/04/971665149402_.pic_hd.jpg"),
            require("/src/assets/photos/04/981665149404_.pic_hd.jpg"),
            require("/src/assets/photos/04/991665149405_.pic_hd.jpg"),
          ],
        },
        {
          name: "10.05 李少卡车超绝花甲和炒鸡",
          photos: [
            require("/src/assets/photos/05/1001665149949_.pic_hd.jpg"),
            require("/src/assets/photos/05/1011665149950_.pic_hd.jpg"),
            require("/src/assets/photos/05/1021665149951_.pic_hd.jpg"),
          ],
        },
        {
          name: "10.06 没起床的海洋公园",
          photos: [
            require("/src/assets/photos/06/1061665150285_.pic.jpg"),
            require("/src/assets/photos/06/1071665150288_.pic.jpg"),
            require("/src/assets/photos/06/1081665150291_.pic.jpg"),
            require("/src/assets/photos/06/1091665150293_.pic.jpg"),
            require("/src/assets/photos/06/1101665150299_.pic.jpg"),
            require("/src/assets/photos/06/1111665150302_.pic.jpg"),
            require("/src/assets/photos/06/1121665150323_.pic.jpg"),
            require("/src/assets/photos/06/1131665150327_.pic.jpg"),
            require("/src/assets/photos/06/1151665150334_.pic.jpg"),
            require("/src/assets/photos/06/1161665150340_.pic.jpg"),
            require("/src/assets/photos/06/1171665150343_.pic.jpg"),
            require("/src/assets/photos/06/1031665150179_.pic_hd.jpg"),
            require("/src/assets/photos/06/1041665150179_.pic_hd.jpg"),
            require("/src/assets/photos/06/1051665150180_.pic_hd.jpg"),
          ],
        },
      ],
      orShow: false,
      inputPasswd: "",
      localVal: localStorage.getItem("vvHanRZ"),
    };
  },
  methods: {
    setLocal() {
      localStorage.setItem("vvHanRZ", this.inputPasswd);
      this.localVal = this.inputPasswd;
    },
  },
};
</script>
<style>
.el-collapse-item__header {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
  border-bottom: 1px solid #dfdfdf;
  height: 46px !important;
}

.el-collapse {
  border: 1px solid #dfdfdf;
  border-bottom: none;
  border-top: none;
}
</style>
<style lang="less" scoped>
.list {
  .itm-icon {
    width: 18px;
    padding: 0px 12px;
  }

  .edit {
    box-sizing: border-box;
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6px;
  }
}
</style>